<template>
  <div id="addBox">
    <h3>新增患者</h3>
    <div>
      <el-form :label-position="labelPosition" :model="list[0]">
        <el-form-item label="患者姓名">
          <el-input v-model="list.CNAME" label-width="80px"></el-input>
        </el-form-item>
        <el-form-item label="患者年龄">
          <el-input v-model="list.Age" label-width="80px"></el-input>
        </el-form-item>
        <el-form-item label="患者性别">
          <el-select v-model="list.Sex">
            <el-option value="男"></el-option>
            <el-option value="女"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="身份证号">
          <el-input v-model="list.CID" label-width="80px"></el-input>
        </el-form-item>
        <el-form-item label="就诊卡号">
          <el-input v-model="list.HospitalID" label-width="80px"></el-input>
        </el-form-item>
        <el-form-item label="既往病史">
          <el-input v-model="list.History" label-width="80px"></el-input>
        </el-form-item>

        <el-form-item label="联系电话">
          <el-input v-model="list.phone" label-width="80px"></el-input>
        </el-form-item>
        <el-form-item label="患者地址">
          <el-input v-model="list.address" label-width="80px"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="adds">提交</el-button>
        </el-form-item>
        <!-- </el-form-item> -->
      </el-form>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
import { createNamespacedHelpers } from "vuex";
import api from "../http/api";
const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
  "patient"
);
export default {
  data() {
    return {
      labelPosition: "right",
      list: {
        CID: "",
        CNAME: "",
        Age: "",
        Sex: "",
        HospitalID: "",
        phone: "",
        History: "",
        address: "",
      },
    };
  },
  methods: {
    // ...mapActions(["addPatient"]),
    adds() {
      const data = api.patient.addPatient(this.list);
      // this.addPatient(this.list);
      // console.log(data);
      Message.success("新增患者成功");
      this.list = "";
      // this.$router.push("/System/ReportList");
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.list.name) {
      const isLeave = confirm("您还有数据没有提交，确定要离开吗？");
      if (isLeave) {
        next();
      }
    } else {
      next();
    }
  },
};
</script>

<style lang='scss' scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
#addBox {
  width: 400px;
  margin: auto;
  text-align: center;
}
.el-form-item {
  width: 300px;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  margin: 10px;
  .el-select {
    width: 200px;
  }
}
</style>
